import { graphql } from 'gatsby';
import * as React from 'react';
import PrivacySection from '../components/PrivacySection/PrivacySection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const PrivacyPage = ({ data, location }) => {
  const companyName = data.site.siteMetadata.companyName;
  return (
    <div>
      <Layout>
        <PrivacySection companyName={companyName} />
      </Layout>
    </div>
  );
};

export default PrivacyPage;

export const Head = () => (
  <Seo
    title="プライバシーポリシー"
    cover={'/main.png'}
    description="プライバシーポリシーのページです"
  />
);

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        companyName
      }
    }
  }
`;
